import { createInstance } from "@datapunt/matomo-tracker-react";

export default createInstance({
  urlBase: MATOMO_URL,
  siteId: MATOMO_SITE_ID,
  heartBeat: {
    active: true,
    seconds: 3,
  },
  configurations: {
    disableCookies: true,
    setRequestMethod: "POST",
  },
});
