import { useEffect, useState } from "preact/hooks";
import { installRouter } from "pwa-helpers";
import { Text } from "preact-i18n";
import jwtDecode from "jwt-decode";

import { Suspense, lazy } from "preact/compat";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";

import { refreshTokens } from "../../lib/apollo/graphqlMiddleware";
// import NewEditor from "../NewEditor/NewEditor";

const Loading = () => <Text id="loading" />;

const WaitComponent = ({ Component, ...rest }) => (
  <Suspense fallback={<Loading />}>
    <Component {...rest} />
  </Suspense>
);

const NotFound = lazy(() => import("../NotFound/NotFound"));
const Main = lazy(() => import("../Main/Main"));
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const Popup = lazy(() => import("../Popup/Popup"));

const Docs = lazy(() => import("../Docs/Docs"));

const OAuth = lazy(() => import("../OAuth/OAuth"));
const UsernamePage = lazy(() => import("../UsernamePage/UsernamePage"));

const Knowledge = lazy(() => import("../Knowledge/Knowledge"));
const Crypto = lazy(() => import("../Crypto/Crypto"));

const routes = [
  { path: "*", element: <WaitComponent Component={NotFound} /> },
  { path: "/", element: <WaitComponent Component={Main} /> },
  // { path: "/edit", element: <NewEditor /> },
  { path: "/oauth", element: <WaitComponent Component={OAuth} /> },
  { path: "/signup", element: <WaitComponent Component={UsernamePage} /> },
  { path: "/popup/*", element: <WaitComponent Component={Popup} /> },
  { path: "/docs/*", element: <WaitComponent Component={Docs} /> },
  {
    path: "/dashboard/*",
    element: <WaitComponent Component={Dashboard} />,
  },
  {
    path: "/f/*",
    element: <div>😏</div>,
  },
  { path: "/knowledge/*", element: <WaitComponent Component={Knowledge} /> },
  { path: "/crypto/*", element: <WaitComponent Component={Crypto} /> },
];

const isLoggedIn = () => {
  const token = window.localStorage.getItem("refreshToken");

  return Boolean(token);
};

const PRIVATE_ROUTES = [/^\/dashboard.*/, /^\/popup.*/];

async function handleAuth() {
  const refreshToken = window.localStorage.getItem("refreshToken");

  if (refreshToken) {
    await refreshTokens();
  }
  const { meta } = jwtDecode(window.localStorage.getItem("accessToken"));

  return meta;
}
function App() {
  const [warmup, setWarmup] = useState(true);
  const navigate = useNavigate();
  const element = useRoutes(routes);
  const { pathname } = useLocation();

  // TODO Determine auth status

  useEffect(() => {
    async function run() {
      const inbox = window.localStorage.getItem("INBOX");

      if (!inbox) return setWarmup(false);
      if (pathname.includes("dashboard")) return setWarmup(false);
      if (pathname.includes("popup")) return setWarmup(false);

      if (window.localStorage.getItem("tokenExpires") < Date.now() - 5) {
        await handleAuth();

        if (pathname !== "/") return setWarmup(false);

        if (inbox) navigate(`/dashboard/${inbox}`);
        else navigate(`/dashboard`);

        setWarmup(false);
      }
    }

    run();
  }, [navigate, pathname, setWarmup]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const loggedIn = isLoggedIn();

    installRouter((location) => {
      if (location.hash)
        document
          .querySelector(location.hash)
          ?.scrollIntoView({ behavior: "smooth" });

      const href = location.pathname + location.search + location.hash;

      navigate(location, {
        replace: true,
      });

      if (loggedIn) return;
      // Navigated to an Auth protected route
      if (PRIVATE_ROUTES.some((route) => route.test(href))) {
        navigate("/");
      }
    });
  }, [navigate]);

  // TODO Check if authenticated and then redirect to /inbox

  // Don't route during warmup
  if (warmup) return <div />;

  return element;
}

export default App;
