// Must be the first import
if (process.env.NODE_ENV !== "production") {
  // Must use require here as import statements are only allowed
  // to exist at the top of a file.
  require("preact/debug"); // eslint-disable-line global-require
}

import { render } from "preact";

import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "preact-i18n";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";

import MatomoInstance from "./lib/matomo/instance";

import "tailwindcss/tailwind.css";
import "./style.css";

import ApolloClient from "./lib/apollo/client";
import "./info";

import App from "./components/App/App";

import locales from "./locales";

const Index = () => {
  return (
    <ApolloProvider client={ApolloClient}>
      <IntlProvider definition={locales} scope="en">
        <MatomoProvider value={MatomoInstance}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MatomoProvider>
      </IntlProvider>
    </ApolloProvider>
  );
};

render(h(Index), document.querySelector(".root")); // eslint-disable-line no-undef
