export default {
  en: {
    loading: "Loading...",

    Docs: {
      home: {
        search: "Search documentation",
        noOptionFound: "Nothing was found",
        heading: "Wasc Documentation",
        description: "Find what you're looking for",
        cta: "Search our documentation",
        imageAlt: "Graphic of people doing organization",
        allPages: "All Pages",
      },
      allPages: { headline: "All Pages" },
      lastEdited:
        '<span class="italic">Last edited on:&nbsp;</span><span class="font-semibold">{{date}}</span>',
      byAuthor: "By",
    },
    AddressInput: {
      recipient: {
        placeholder: "Recipient",
        aria: "recipient",
      },
    },
    Popout: {
      confirmDiscarding: "Are you sure you want to discard your message?",
    },
    MessageListDisplayStyleButton: {
      group: "Group messages by Sender",
      chronological: "Display in pure chronological order",
    },
    EmptyMailbox: {
      sunlight: {
        day: "Go out! Get some sunlight!",
        night: "Go to bed, you night owl!",
      },
      search: "Nothing found, try something else...",
      sent: "Nothing sent yet, let's reach out to your best friend, he or she misses you!",
      junk: "No junk, phew!",
      trash: "Nothing here, this must be THE cleanest trash",
      archive:
        "You don't like saving things? Someone is careful about their privacy!",
      inbox: "All done. {{sunlight}}",
      generic: "All empty. Carry on!",
    },
    Header: {
      accountSettings: "Account Settings",
      settings: "Dashboard Settings",
      logout: "Logout",

      helpMenu: "Help",

      question: {
        button: "I have a question",
        subject: "Wasc.me question",
      },
      inboxOverflowing: {
        button: "My inbox is overflowing",
        sub: "Get a consulting session with our staff",
        subject: "Wasc.me overflowing inbox",
        body: "Let us know, when you'll have time for a consultation\n",
      },
      notWorking: {
        button: "Something isn't working",
        subject: "Wasc.me something isn't working",
        body: "Describe your issue:",
      },
    },

    NewObfuscatedAddressButton: {
      tooltip: "Generate Obfuscated Address",
      label: "New Alias",
    },
    DashboardDemo: {
      obfuscatedMessage: {
        from: "Generic corporation",
        subject: "We have your personal address, muahahaha",
      },
      aliasHeading:
        "Only your closest trustees will know your personal address",
      whyAlias: `Generate deleteable aliases, like <span class="underline text-blue-400">{{alias}}</span> for each service that shouldn't know your personal address`,

      unsubscribeMessage: {
        from: "Generic corporation",
        subject: "Not a newsworthy-letter",
        body: "Guess what, you're never gonna find the unsubscribe link",
      },
      unsubscribe: "Easily unsubscribe at the top of each newsletter",
      whyUnsubscribe:
        "We think it's important you get the choice to unsubscribe whenever you want",
      next: "Next",
      block: {
        from: "That large Social Media Corporation",
        subject: "We will track your every move 🔍",
        body: `You don't stand a chance, we know you opened the email.<br />Maybe click on <a href="http://thebestmotherfuckingwebsite.com/" target="_blank" class="text-blue-400 underline">this link</a>, I dare you!`,
      },
      postalSecrecy: "We maintain the postal secrecy for you",
      blockPixels:
        "We block tracking pixels and we remove tracking information on links inside the emails",
      close: "Close",
      signMeUp: "Sign me up",
      from: "from",
      minutesAgo: "{{minutes}} minutes ago",
    },
    ConnectionPopover: {
      connected: "Connected to Falkenstein, Germany",
      transport: "TLS encrypted connection",
      rest: "Data encrypted at rest",
    },
    MailboxesDrawer: {
      obfuscatedAliases: "Obfuscation Aliases",
      create: "Create new Mailbox",
      saveTooltip: "Save",
      closeTooltip: "Close",
      mailboxName: "Mailbox Name",
      rules: {
        required: "You must provide a name",
        length: "The name can't be longer than {{count}} characters",
      },
      delete: {
        tooltip: "Delete Mailbox",
        confirm: "Confirm deletion",
      },
    },
    MailInput: {
      defaultValue: "May it please Your Majesty...",
    },
    Main: {
      tagline: {
        privacy: "Your email privacy",
        protected: "protected",
      },
      subline: "The e-mail service for privacy enthusiasts.",
      subsubline: "Clean interface, trackers blocked: 2,99 € / month",
      startTrial: "Start 0 € Trial",
      nextCohort: "Next cohort opens {{relativeTime}}",
      FAQLine: "Learn more: FAQ",
      FAQ: "FAQ",

      chooseUsername: "Choose a username first",
      signupTooltip: "Let's go!",

      howProtect: "How we protect you",

      WascBlog: "Wasc Blog",
      WascShort: "Wasc Short",
      imprintPrivacy: "Imprint & Privacy Policy",
      terms: "Terms of Service",
    },
    Message: {
      unsubscribeTooltip: "Unsubscribe from List",
      replyText: "Reply...",
      forward: "Forward",
    },
    MessageActions: {
      restore: "Restore to Inbox",
      archive: "Archive Message",
      delete: "Delete Message",
      send: "Send Draft",
    },
    MessageList: {
      deselectAll: "Deselect all",
      selectAll: "Select All",
      selectedMessages: {
        none: "No message:",
        one: "One message:",
        many: "{{amount}} messages:",
      },
      numberOfMessages: "Number of messages, click to hide",
      showingMessageCount: "Showing {{numerator}} of {{denominator}}",
      loadMore: "Load more",
      notFound: "Mailbox not found",
    },
    NewEmail: {
      dropAttachments: "Drop attachments here",
      newEmail: "New Email",
    },
    OAuth: {
      authenticating: "Authenticating",
    },
    RegisterMailHandler: {
      title: "Would you like to automatically open email links in Wasc.me?",
      yes: "Yes",
      no: "No",
    },
    Payment: {
      completeHeading: "Wasc.me - Complete Your Subscription",
      secured: "Your digital life secured!",
      address:
        'Your chosen address: <span class="underline text-blue-400">{{address}}</span>',
      trial: "30 day free trial",
      howLong: "Choose how long you would like to commit:",
      aMonth: "a Month<br />(2,99 €)",
      aQuarter: "a Quarter<br />(7,99 €)",
      aYear: "a Year<br />(29 €)",
      saveWithQuarter: "Save ~11 % with the quarterly plan",
      saveWithYear: "Save ~17 % with the yearly plan",
      trueSaver: "That's it! You're a true saver",
      subscribe: "Subscribe for {{price}} €",
      completePayment: "Complete Payment",
    },
    ObfuscationDashboard: {
      heading: "Obfuscated Aliases",
      noAliases: "No aliases yet, let's create one",
      colorPickTooltip: "Pick a color for the alias",
      pickMarkerColor: "Pick the color of the marker",
      aliasDescriptionInputLabel: "Note where you used the alias",
      filterTooltip: "Filter for alias",
      filterLabel: "Filter...",
      clipboardTooltip: "Copy address to clipboard",
      editTooltip: "Edit the note of the alias",
      deleteAliasTooltip: "Delete the alias",
      confirmDeleteTooltip: "Confirm deletion",
    },
    ParticipantsText: {
      aliasTooltip: "Obfuscated Alias - Note: {{note}}",
      me: "Me",
    },
    Search: {
      heading: "Search",
      description: "Let's search a little, shall we?",
    },
    SearchBar: {
      label: "Search Query",
      placeholder: "Type query: NASA introduces Astronaut {{firstname}}...",
      allMailboxes: "All Mailboxes",
      allMailboxesShort: "All",
    },
    SendButton: {
      scheduledTooltip: "Will be sent",
      sendTooltip: "Send message",
      sendButton: "Send",
    },
    LoginButton: "Login with Wasc",
    Settings: {
      ApplicationPasswords: "Application Passwords",

      ObfuscationStyle: "Obfuscation Alias Style",
      ObfuscationStyleDescription: "Which alias style to you prefer?",
      usingHuman: "You are currently using human style aliases",
      changeToHuman: "Change to human style aliases",
      usingRandom: "You are currently using random style aliases",
      changeToRandom: "Change to random style aliases",

      newMailEditorMode: {
        heading: "New Email Editor Mode",
        description:
          "Which mode do you prefer for the new email editor? Should the new mail button open a panel or a pop-out by default?",
        selectLabel: "Mode",
        modes: { panel: "Panel", popout: "Popout", default: "(Default)" },
      },
      numberOfMessagesToFetch: {
        heading: "How many messages should be fetched by default?",
        description:
          "Please enter the number of messages that should be fetched by default. Leave empty for default and 0 for infinite.",
        label: "Amount",
      },
      showCounters: {
        heading: "Should counters be displayed?",
        description:
          "There are a few places where counters can be displayed. Should they be hidden or shown?",
        label: "Display counters",
        clear: "Reset to default",
      },
      specialUnreadEmphasis: {
        heading: "Emphasize messages with colour",
        description:
          "Colour the box of a message to show its unread status more clearly",
        label: "Special Unread Emphasis",
        clear: "Reset to default",
      },
    },
    Signup: {
      heading: "Select your username",
      description: `This is where you choose your username.<br />It'll be your <span class="font-bold">identity</span> for Wasc.me Mail.<br class="mb-3"/>Like an ID, showing the world that you <span class="underline">care about privacy</span>!<br class="mb-3" />It's where you'll receive your messages and where others can reach you.<br class="mb-3" />You have the <span class="italic">power</span> to choose any username you'd like, so choose wisely;<br /><span class="mt-4">With great power comes great responsibility.</span>`,
      emailInput: "Email address",
      signupButton: "Signup now",
    },
    ApplicationPasswords: {
      heading: "Application Passwords",
      new: {
        tooltip: "Create a new application password",
        text: "New",
      },
      delete: {
        tooltip: "Delete the application password",
        tooltipConfirm: "Confirm deletion",
      },
      copy: "Copy to Clipboard",
      copyAria: "Copy application password",

      downloadMobileConfig: "Download Apple MobileConfig",
      close: "Close",

      createDescriptor: "Create a new application password:",

      rules: {
        description: "You must provide a description",
        maxLength: "The description can't be longer than {{count}} characters",
      },

      description: {
        label: "Description",
        placeholder: "iPhone...",
      },

      createButton: { label: "Create", tooltip: "Confirm the creation" },
      newDescription:
        "This is your new application password, please use it to log in your device:",
      empty: "No Application Passwords yet",
    },
  },
};
